export default defineNuxtRouteMiddleware(async (to, from) => {

    const operation = useOperation()
    const {get} = useOFetchCustom(`operations/${to.params.operation}/show`)

    try{
        await new Promise((resolve, reject) => {
            get(undefined, {
                onSuccess: (response: any) => {
                    operation.value = response.data
                    resolve(response)
                },
                onError: (error: any) => {
                    reject(error)
                }
            })
        })
    }
    catch (error: any) {
        if(error.status == 403) {
            return navigateTo('/store')
        } 
    }

})
